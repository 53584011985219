<template>
  <div>
    <b-row class="match-height">
      <b-col
        v-for="(startup, index) in startups"
        :key="index"
        xs="12"
        md="4"
      >
        <b-card no-body>
          <b-card-body
            class="pb-0"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <b-avatar
                  v-if="!startup.image"
                  :text="startup.avatar_text"
                  variant="primary"
                  size="48"
                />
                <b-avatar
                  v-else
                  :src="startup.image"
                  variant="primary"
                  size="48"
                />
              </div>
              <div class="w-100 overflow-text px-1">
                <b-card-title class="mb-1 overflow-text">
                  {{ startup.title }}
                </b-card-title>
                <b-card-sub-title class="overflow-text">
                  {{ startup.company }}
                </b-card-sub-title>
              </div>
              <div>
                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click="setFavorite(startup.id, index)"
                >
                  <FeatherIcon
                    icon="HeartIcon"
                    :class="startup.favorite ? 'favoriteIcon' : ''"
                  />
                </b-button>
              </div>
            </div>
            <b-row class="hidden">
              <b-col cols="auto">
                <b-avatar
                  v-if="!startup.image"
                  :text="startup.avatar_text"
                  variant="primary"
                  size="48"
                />
                <b-avatar
                  v-else
                  :src="startup.image"
                  variant="primary"
                  size="48"
                />
              </b-col>
              <b-col cols="8">
                <b-card-title class="mb-1 overflow-text">
                  {{ startup.title }}
                </b-card-title>
                <b-card-sub-title class="overflow-text">
                  {{ startup.company }}
                </b-card-sub-title>
              </b-col>
              <b-col cols="auto">
                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click="setFavorite(startup.id, index)"
                >
                  <FeatherIcon
                    icon="HeartIcon"
                    :class="startup.favorite ? 'favoriteIcon' : ''"
                  />
                </b-button>
              </b-col>
            </b-row>
            <div class="text-center mt-1">
              <ul class="list-inline">
                <li
                  v-for="(focuses, fKey) in startup.marketing_focus"
                  :key="fKey"
                  class="list-inline-item"
                >
                  <b-badge
                    variant="light-success"
                  >
                    {{ focuses }}
                  </b-badge>
                </li>
              </ul>
            </div>
          </b-card-body>
          <b-card-body class="py-0 height-100 overflow-body-text mb-1">
            {{ startup.description }}
          </b-card-body>
          <b-card-footer class="text-center">
            <b-button
              variant="primary"
              :to="'girisimler/' + startup.seourl"
            >
              <FeatherIcon icon="EyeIcon" />
              {{ $t('Görüntüle') }}
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardBody, BCardFooter, BAvatar, BBadge, BButton, BPagination,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardFooter,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 12,
      start: 0,
    }
  },
  computed: {
    startups() {
      return this.$store.getters['startups/getStartups']
    },
    dataCount() {
      return this.$store.getters['startups/getStartupsCount']
    },
    favoriteToggleStatus() {
      return this.$store.getters['favorites/getFavoritesToggleStatus']
    },
    filterData() {
      return this.$store.getters['startups/getStartupFilterData']
    },
  },
  watch: {
    filterData: {
      deep: true,
      handler(val) {
        if (val) {
          this.getStartups()
        }
      },
    },
    favoriteToggleStatus(val) {
      if (val.message !== null) {
        this.makeToast(val)
      }
    },
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getStartups()
  },
  methods: {
    pagination(page) {
      this.start = page
      if (this.start === page) {
        this.getStartups()
      }
    },
    getStartups() {
      this.$store.dispatch('startups/startupsList', { start: this.start, filterData: this.filterData })
    },
    setFavorite(id, index) {
      this.startups[index].favorite = !this.startups[index].favorite
      this.$store.dispatch('favorites/favoritesToggle', id)
    },
    makeToast(params) {
      let variant = 'success'
      if (!params.status) {
        variant = 'danger'
      }
      this.$bvToast.toast(params.message, {
        title: this.$t('İşlem Başarılı'),
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style lang="sass">
.favoriteIcon
  fill: #FF7032
.overflow-text
  white-space: nowrap /* Metnin satıra sığmasını engelle */
  overflow: hidden /* Taşan kısmı gizle */
  text-overflow: ellipsis /* Taşan kısmın yerine ... (üç nokta) koy */
.overflow-body-text
  overflow: hidden /* Taşan kısmı gizle */
  text-overflow: ellipsis /* Taşan kısmın yerine ... (üç nokta) koy */
</style>
